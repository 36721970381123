import { abtservice } from 'public/src/services/abt'

const getPageSource = () => {
  // pwa pc 差异化
  return PageGroup
}

const pageInfoMapping = (code) => {
  let info = {}
  switch(code) {
    case 'wishlist':
      info['overview'] = info['scenes'] = '收藏夹'
      break
    case 'vipBirthGiftList':
      info['overview'] = '生日礼商品列表页'
      info['scenes'] = '生日礼商品列表页'
      break
    case 'vipExclusiveHome':
      info['overview'] = '会员权益页'
      info['scenes'] = '会员权益页'
      break
    case 'styleGallery':
      info['overview'] = 'other'
      info['scenes'] = '风格廊'
      break
    case 'styleGallery-list':
      info['overview'] = '列表页'
      info['scenes'] = '风格廊'
      break
    case 'productListRecommend':
    case 'goodsList':
      info['overview'] = info['scenes'] = '列表页'
      break
    case 'searchProductListRecommend':
      info['overview'] = '搜索列表页'
      info['scenes'] = '列表页'
      break
    case 'realProductListRecommend':
      info['overview'] = '分类页'
      info['scenes'] = '列表页'
      break
    case 'recommendProductDetailCustomersAlsoViewed':
    case 'recommendProductDetailBestSeller':
    case 'recommendProductDetailYouMayAlsoLike':
    case 'detaiMain':
      info['overview'] = info['scenes'] = '商品详情页'
      break
    case 'recommendProductDetailCustomersAlsoViewedList':
      info['overview'] = '商品详情页'
      info['scenes'] = '推荐列表直接加车'
      break
    case 'recommendEmptyCartYouMayAlsoLike':
      info['overview'] = '空购物车'
      info['scenes'] = '推荐列表'
      break
    case 'recommendCartYouMayAlsoLike':
      info['overview'] = '购物车页'
      info['scenes'] = '推荐列表'
      break
    case 'cartBuyGifts':
    case 'cartFullGifts':
      info['overview'] = '购物车页'
      info['scenes'] = '满赠'
      break
    case 'cartAddBuys':
      info['overview'] = '购物车页'
      info['scenes'] = '加价购'
      break
    case 'recommendCartSavedItems':
      info['overview'] = '购物车页'
      info['scenes'] = '收藏夹'
      break
    case 'similarItem':
    case 'similarRecommendating':
      info['overview'] = '相似推荐结果页'
      info['scenes'] = '推荐列表'
      break
    case 'wishlistListRecommend':
      info['overview'] = '收藏夹'
      info['scenes'] = '推荐列表'
      break
    case 'orderDetailRecommend':
      info['overview'] = '订单详情页'
      info['scenes'] = '推荐列表'
      break
    case 'orderListRecommend':
      info['overview'] = '订单列表页'
      info['scenes'] = '推荐列表'
      break
    case 'errorRecommend':
      info['overview'] = 'oops'
      info['scenes'] = '推荐列表'
      break
    case 'userOrderTrackRecommend':
      info['overview'] = '物流轨迹页'
      info['scenes'] = '推荐列表'
      break
    case 'cartAddItem':
      info['overview'] = '购物车凑单页'
      info['scenes'] = '凑单页'
      break
    case 'recentlyViewd':
      info['overview'] = info['scenes'] = '最近浏览'
      break
    case 'getTheLook':
      info['overview'] = '商品详情页'
      info['scenes'] = 'GetTheLook'
      break
    case 'otherOptions':
      info['overview'] = '商品详情页'
      info['scenes'] = 'OtherOptions'
      break
    case 'wishlistHistory':
      info['overview'] = info['scenes'] = '收藏夹'
      break
    case 'activity':
      info['overview'] = info['scenes'] = '专题页'
      break
    case 'PaymenSuccessRecommend':
      info['overview'] = '支付成功页'
      info['scenes'] = '推荐列表'
      break
    case 'group':
      info['overview'] = info['scenes'] = '收藏分组商品页'
      break
    case 'groupShare':
      info['overview'] = info['scenes'] = '收藏分享页'
      break
    case 'couponProduct':
      info['overview'] = '券可用商品承接页'
      info['scenes'] = '列表页'
      break
    default:
      info['overview'] = info['scenes'] = 'other'
      break
  }
  return info
}

/**
 * 从祖先元素获取信息
 * @param {target} dom
 *
 * @return
 * @param {Dom} container 匹配到.j-da-event-box 最近祖先容器
 * @param {String} title 推荐位名称，没有不传即可
 * @param {String} poskey 实验poskey
 * @param {String} code 列表标识
 * @param {String} isFault 是否走了容错 1 / 0
 * @param {String} dataType 数据来源，own, emarsys
 * @param {String} style 点击商品之后是跳详情页面还是 弹出其他弹窗，popup, detail
 * @param {String} traceId 自有购物流程id
 * @param {String} activityFrom  这个点击事件发生在哪个页面上（根据需求文档），sa使用
 * @param {Boolean} isModule  区分是推荐商品列表 还是 普通商品列表 (如果是普通列表则不用传)
 * @param {Number} pageNum  页码不传默认是1
 * @param {String} tagIds  列表-标签云id
 * @param {String} attrStr  列表-属性
 * @param {String} cat  列表-分类
 * @param {String} minPri  列表-最低价格
 * @param {String} maxPri  列表-最高价格
 * @param {String} request_ext  列表-算法跟踪
*/
const getAttrFromContainer = (target) => {
  if(!target) return
  if (target.length) {
    target = target[0]
  }
  let container = target?.closest?.('.j-da-event-box'),
      title = '',
      poskey = '',
      code = '',
      isFault = 0,
      dataType = '',
      style = 'detail',
      parentGoodsId = '',
      traceId = '',
      dimension = '',
      activityFrom = '',
      isModule = false,
      pageNum = 1,
      request_ext = '',
      tagIds = '',
      attrStr = '',
      cat = '',
      minPri = '',
      maxPri = 0,
      extraPramas = null,
      srcModule = '',
      srcIdentifier = '',
      abtest = '',
      beacon = 1,
      tabList = '',
      type = '',
      time = '',
      activity_id = '',
      location = '',
      feed_type = '',
      query_ts = ''
    
  if (container) {
    title = container.getAttribute('data-title') || ''
    poskey = container.getAttribute('data-poskey') || ''
    code = container.getAttribute('code') || ''
    parentGoodsId = container.getAttribute('data-parent-goods-id') || ''
    isFault = container.getAttribute('data-is-fault-tolerant') || 0
    dataType = container.getAttribute('data-dataType') || ''
    style = container.getAttribute('data-style') || 'detail'
    traceId = container.getAttribute('data-traceid') || ''
    dimension = container.getAttribute('data-dimension') || ''
    activityFrom = container.getAttribute('data-active-from') || ''
    isModule = container.getAttribute('data-module') || false
    pageNum = container.getAttribute('data-page-num') || 1,
    request_ext = container.getAttribute('data-request_ext') || '',
    // 普通列表才需要
    tagIds = container.getAttribute('data-tag_ids') || ''
    attrStr = container.getAttribute('data-attr_str') || ''
    cat = container.getAttribute('data-cat') || ''
    minPri = container.getAttribute('data-min-pri') || 0
    maxPri = container.getAttribute('data-max-pri') || 0
    //目前只有套装详情页需要
    extraPramas = container.getAttribute('data-extra-pramas') || ''
    location = container.getAttribute('data-location') || ''

    srcModule = container.getAttribute('data-src-module') || ''
    srcIdentifier = container.getAttribute('data-src-identifier') || ''
    abtest = container.getAttribute('data-abtest') || ''
    beacon = container.getAttribute('data-beacon') === '0' ? 0 : 1
    tabList = container.getAttribute('data-tab-list') || ''
    // 闪购列表
    type = container.getAttribute('data-type') || ''
    time = container.getAttribute('data-time') || ''
    activity_id = container.getAttribute('data-activity-id') || ''

    // 商详
    feed_type = container.getAttribute('data-feed_type') || ''
    // query_ts单独从一个容器拿
    query_ts = container.querySelector('#j-query-ts-container')?.getAttribute('data-query-ts') || ''
  }
  
  

  return {
    container,
    title,
    poskey,
    code,
    isFault,
    dataType,
    style,
    traceId,
    dimension,
    activityFrom,
    isModule,
    pageNum,
    parentGoodsId,
    request_ext,
    tagIds,
    attrStr,
    cat,
    minPri,
    maxPri,
    extraPramas,
    srcModule,
    srcIdentifier,
    abtest,
    beacon,
    tabList,
    type,
    time,
    activity_id,
    location,
    feed_type,
    query_ts
  }
}

/**
 * 从当前target 获取信息
 * @param {target} dom
 * @returns
 * @param {Number} index
 * @param {String} spu
 * @param {String} sku
 * @param {String} catId 分类id
 * @param {Number} goodsId 商品id
 * @param {Number} price 价格
 * @param {Number} reducePrice 降价标示
 * @param {Number} operId 运营位置(首页底部是-1，其它默认是1)
 * @param {String} recMark 流量标示
 * @param {String} extraMark 置顶标示
 * @param {String} ortherExtMark 剩余拼接好的ext标示
 * @param {String} otherDExtMark 其他dynamic_ext标示
 * @param {String} cccTspBadges 商品ccc-tsp角标
 * @param {String} seriesOrBrand 商品系列 shein高端（premium）、romwe设计师款（#ROMWEcreators）、romwe单独款（#ROMWEoriginals） 或者 品牌  shein/romwe
 * @param {String} sellingPointLabel 卖点
 * @param {String} userAct 卖点
*/
const getAttrFromTarget = (target) => {
  let index = 0,
      spu = '',
      sku = '',
      catId = '',
      goodsId = '',
      price = 0,
      originPrice = 0,
      reducePrice = 0,
      operId = 1,
      recMark = '',
      soldoutMart = 0,
      extraMark = '',
      ortherExtMark = '',
      otherDExtMark = '',
      extMarks = '',
      exclusivePrice = '',
      lable = '', //promotion lable
      cccTspBadges = '',
      seriesOrBrand = '',
      sellingPointLabel,
      soldOutSign = 0,
      soldoutSt = '',
      brand = '',
      brandCode = '',
      videoIcon = '',
      userAct = '',
      spuImg = '',
      mallCode = '',
      storeCode = '',
      sellAttr = '',
      promotionId = [],
      typeId = [],
      quickShip = '',
      bestDeal = '',
      followLabel = '',
      promoLabel = '',
      userBehaviorLabel = '',
      userCommentKeywordsLabel = '',
      beltLabel = '',
      decisionAttr = '',
      locateLabels = '',
      historicalPrice = '',
      followPrice = '',
      findSimilarMode = '',
      rankingList = '',
      estimatedPriceInfo = [],
      showLabelHot = '',
      showSellerLimitedLabel = '',
      labelLooping = '',
      serviceLabel = '',
      titleLabel = '',
      horseRaceLabel = '',
      rrpPrice = '',
      discountCountDown = '',
      extraTag = '',
      hypernymGoods = '',
      salesLabel = '',
      flashSale = '',
      lookType = '',
      lookName = '',
      isABPrice = '',
      labelCarousels = '',
      realLeftStock = '',
      trendWordId = '',
      qualityWordId = '',
      qualityWordName = '',
      trendProductSelectId = '',
      trendShopCode = '',
      newUserVoucher = '',
      localGoodsId = '',
      maskLayer = ''
  if (target) {
    // v3商卡才有的标签 start
    const v3CardlabelLooping = target.getAttribute('data-label-looping')
    const v3CardlServiceLabel = target?.getAttribute('data-service-label')
    const v3CardTitleLabel = target?.getAttribute('data-title-label')
    const v3CardHorseRaceLabel = target?.getAttribute('data-horse-race-first')
    const v3CardSalesLabel = target?.getAttribute?.('data-sales-label') ?? ''
    const v3CardRRPPrice = target?.getAttribute?.('data-rrp-price') ?? ''
    const v3CardDiscountCountDown = target?.getAttribute?.('data-discount-count-down') ?? '' 
    const v3CardUserVoucher = target?.getAttribute('data-user-voucher') || ''
    
    // 需要额外上报的标签
    const v3ExtraTagProposition = target?.getAttribute('data-extra-tag-proposition')
    // v3商卡才有的标签 end

    index = target.getAttribute('data-index') || target.getAttribute('index') || 0
    spu = target.getAttribute('data-spu') || ''
    sku = target.getAttribute('data-sku') || ''
    catId = target.getAttribute('data-cat_id') || target.getAttribute('data-cat-id') || ''
    goodsId = target.getAttribute('data-goods_id') || target.getAttribute('data-id')
    price = target.getAttribute('data-us-price') || target.getAttribute('data-price') || 0
    originPrice = target.getAttribute('data-us-origin-price') || 0
    reducePrice = target.getAttribute('data-reduce-price') || 0
    operId = target.getAttribute('data-operId') || 1
    recMark = target.getAttribute('data-rec_mark') || ''
    extraMark = target.getAttribute('data-extra_mark') || ''
    ortherExtMark = target.getAttribute('data-other_ext_mark') || ''
    otherDExtMark = target.getAttribute('data-other_d_ext_mark') || ''
    extMarks = target.getAttribute('data-ext_marks') || ''
    soldoutMart = target.getAttribute('data-soldout') || 0
    soldOutSign = target.getAttribute('data-sold-out') || 0
    soldoutSt = target.getAttribute('data-soldout_st') || ''
    exclusivePrice = target.getAttribute('data-show-exclusive-price') || ''
    lable = target.getAttribute('data-lable') || document.querySelector(`.j-goodsli-promotion-wrap-${goodsId}`)?.getAttribute('data-lable') || '' // 兼容旧代码
    cccTspBadges = target.getAttribute('data-badges') || document.querySelector(`.j-goodsli-promotion-wrap-${goodsId}`)?.getAttribute('data-badge') || '' // 兼容旧代码
    seriesOrBrand = target.getAttribute('data-series-brand') || ''
    sellingPointLabel = target.getAttribute('data-selling-point') || ''
    brand = target.getAttribute('data-brand') || ''
    brandCode = target.getAttribute('data-brand-code') || ''
    videoIcon = target.getAttribute('data-video') || ''
    spuImg = target.getAttribute('data-spu-img') || ''
    userAct = target.getAttribute('data-user-act') || ''
    mallCode = target.getAttribute('data-mall_tag_code')?.split('_')?.[1] || ''
    storeCode = target.getAttribute('data-store_code') || ''
    sellAttr = target.getAttribute('data-sell-attr') || ''
    quickShip = target.getAttribute('data-quick-ship') || ''
    bestDeal = target.getAttribute('data-best-deal') || ''
    followLabel = target.getAttribute('data-follow-label') || ''
    promoLabel = target.getAttribute('data-promo-label') || ''
    userBehaviorLabel = target.getAttribute('data-user-behavior-label') || ''
    userCommentKeywordsLabel = target.getAttribute('data-user-comment-keywords-label') || ''
    beltLabel = target.getAttribute('data-belt-label') || ''
    rankingList = target.getAttribute('data-ranking-list') || ''
    decisionAttr = target.getAttribute('data-decision-attr') || ''
    locateLabels = target.getAttribute('data-locate-labels') || ''
    historicalPrice = target.getAttribute('data-historical-price') || ''
    followPrice = target.getAttribute('data-follow-price') || ''
    findSimilarMode = target.getAttribute('data-find-similar-mode') || ''
    estimatedPriceInfo = target.getAttribute('data-estimated-price')?.split('`') || []
    isABPrice = target.getAttribute('data-is-ab-price') || ''
    flashSale = target.getAttribute('data-flash_sale') || ''
    lookType = target.getAttribute('data-look_type') || ''
    lookName = target.getAttribute('data-look_name') || ''
    showLabelHot = target.getAttribute('data-show-label-hot') || ''
    showSellerLimitedLabel = target.getAttribute('data-show-seller-limited-label') || ''
    trendWordId = target.getAttribute('data-trend-word-id') || ''
    qualityWordId = target.getAttribute('data-list-choice-word-id') || ''
    qualityWordName = target.getAttribute('data-list-choice-word-name') || ''
    trendProductSelectId = target.getAttribute('data-trend-product-select-id') || ''
    trendShopCode = target.getAttribute('data-trend-shop-code') || ''
    localGoodsId = target.getAttribute('local_goods_id')

    const promotionIdAttr = target.getAttribute('data-promotion-id')
    promotionId = promotionIdAttr ? promotionIdAttr?.split('`') : []

    const typeIdAttr = target.getAttribute('data-type-id')
    typeId = typeIdAttr ? typeIdAttr?.split('`') : []


    labelCarousels = target.getAttribute('data-label-carousel') || ''

    labelLooping = v3CardlabelLooping
    serviceLabel = v3CardlServiceLabel
    titleLabel = v3CardTitleLabel
    horseRaceLabel = v3CardHorseRaceLabel
    rrpPrice = v3CardRRPPrice
    discountCountDown = v3CardDiscountCountDown
    extraTag = v3ExtraTagProposition
    salesLabel = v3CardSalesLabel
    newUserVoucher = v3CardUserVoucher
    hypernymGoods = target.getAttribute('data-hypernym-goods') || ''
    realLeftStock = target.getAttribute('data-real-left-stock') || ''
    maskLayer = target.getAttribute('data-mask-layer') || ''
  }

  return {
    index,
    spu,
    sku,
    catId,
    goodsId,
    price,
    originPrice,
    reducePrice,
    operId,
    recMark,
    extraMark,
    ortherExtMark,
    otherDExtMark,
    extMarks,
    soldoutMart: Number(soldoutMart),
    soldOutSign: Number(soldOutSign),
    soldoutSt,
    exclusivePrice,
    lable,
    cccTspBadges,
    seriesOrBrand,
    sellingPointLabel,
    brand,
    brandCode,
    videoIcon,
    spuImg,
    userAct,
    mallCode,
    storeCode,
    sellAttr,
    promotionId,
    typeId,
    quickShip,
    bestDeal,
    followLabel,
    promoLabel,
    userBehaviorLabel,
    userCommentKeywordsLabel,
    beltLabel,
    findSimilarMode,
    rankingList,
    locateLabels,
    historicalPrice,
    followPrice,
    decisionAttr,
    estimatedPriceInfo,
    showLabelHot,
    showSellerLimitedLabel,
    labelLooping,
    serviceLabel,
    titleLabel,
    horseRaceLabel,
    rrpPrice,
    discountCountDown,
    extraTag,
    hypernymGoods,
    salesLabel,
    newUserVoucher,
    isABPrice,
    flashSale,
    lookType,
    lookName,
    labelCarousels,
    realLeftStock,
    trendWordId,
    qualityWordId,
    qualityWordName,
    trendProductSelectId,
    trendShopCode,
    maskLayer,
    localGoodsId,
  }
} 

/**
 * 获取abt信息
 * @param {String} posKeys
*/
const getAnalysisAbtInfo = (posKeys) => {
  let abtInfo = {},
      saAbt = ''

  if (posKeys) {
    abtInfo = abtservice.getUserAbtResultForAnalysis({ posKeys: posKeys })
    // 自有的abt信息拼接
    saAbt = abtInfo.sa || ''
  }

  return {
    abtInfo,
    saAbt,
  }
}

/**
 * 获取ga list属性
*/
// const getGaList = ({ isModule = false, code = '', title = '', isFault = 0, dataType = '', gaAbt = '', tagIds = '', attrStr = '', cat = '',  minPri = 0, maxPri = 0, extraPramas } = {}) => {
//   let list = ''
//   if (isModule) {
//     // 推荐列表
//     // {页面名称}-推荐列表-{推荐位名称}-{Is/NoFaultTolerant}-{推荐来源}-{实验poskey_实验分支类型}
//     list = [
//       pageInfoMapping(code).overview || 'other', '推荐列表', title
//     ]
//     // 兼容quickView quickView的埋点不需要 {Is/NoFaultTolerant}-{推荐来源}-{实验poskey_实验分支类型}
//     dataType && (list = list.concat([isFault == 0 ? 'NoFaultTolerant' : 'IsFaultTolerant', dataType == 'own' ? '自有推荐' : 'emarsys', gaAbt]))
//     list = list.join('-')
//   } else {
//     // 普通列表的list
//     // {分类类型 id/名称}&{来源}&{排序方式}${tag_ids=}&{attr_str=}&{Cat=分类}&{top1}&{top2}&{PriceRange={1_100}};
//     let params = [getPageSource()]
//     if(extraPramas) params[0] += `-${extraPramas}` //套装详情页ID
//     tagIds && params.push(`tag_ids=${tagIds}`)
//     attrStr && params.push(`attr_str=${attrStr}`)
//     cat && params.push(`Cat=${cat}`);
//     (minPri || maxPri) && params.push(`PriceRange=${minPri}_${maxPri}`)
//     list = params.join('&')
//   }
//   return list
// }

/**
 * 获取ga电商代码
*/
// const getGaEcommerceCode = ({ isModule = false, index = 0, spu = '', sku = '', catId = '', price = 0, list = '' } = {}) => {
//   let ecommerceCode = {
//     id: spu || sku,
//     name: sku,
//     category: catId,
//     price: price,
//     position: isModule ? 1000 + Number(index) : Number(index),
//   }
//   if (list) ecommerceCode['list'] = list
//   return ecommerceCode
// }

/**
 * 获取sa goodsList
 * wiki.pageId=904754267
*/
const getSaGoodsList = ({ historicalPrice = '', followPrice = '', locateLabels = '', goodsId = '', sku = '', spu = '', index = 0, pageNum = 1, request_ext = '', operId = 1, recMark = '', extraMark = '', ortherExtMark = '', otherDExtMark = '', extMarks = '', price = 0, originPrice, reducePrice = 0, soldOutSign = 0, soldoutMart = 0, soldoutSt = 0, exclusivePrice = '', lable = '', cccTspBadges = '', seriesOrBrand = '', sellingPointLabel = '', videoIcon = '', spuImg = '', userAct = '', mallCode = '', sellAttr = '', promotionId = '', typeId = '', brand = '', brandCode = '', quickShip = '', bestDeal = '', followLabel = '', promoLabel = '', userBehaviorLabel = '', userCommentKeywordsLabel = '', beltLabel = '', rankingList = '', decisionAttr = '', findSimilarMode = '', estimatedPriceInfo = '', showLabelHot = '', showSellerLimitedLabel = '', labelLooping = '', serviceLabel = '', titleLabel = '', horseRaceLabel = '', rrpPrice = '', discountCountDown = '', extraTag = '', hypernymGoods = '', salesLabel = '', isABPrice = '', flashSale = '', lookType = '', lookName = '',  labelCarousels = '', realLeftStock = '', trendWordId = '', qualityWordId = '', qualityWordName = '', trendProductSelectId = '', trendShopCode = '', newUserVoucher = '', localGoodsId = '', maskLayer = '' }  = {}) => {
  const prices = [`pri_${price}`, `pri_${originPrice}`]
  if (estimatedPriceInfo[1]) prices.push(`estimated_pri_${estimatedPriceInfo[1]}`)
  let list = [
    goodsId, 
    sku, 
    spu, 
    Number(index) + 1, 
    pageNum, 
    operId, 
    recMark, 
    extraMark, 
    prices.join('|')
  ]
  // 其他标识 - S
  const otherMark = []
  if (reducePrice) otherMark.push(`reduced_${reducePrice}`)
  if (soldoutMart) otherMark.push('stock_soldout')
  if (soldOutSign) otherMark.push('show_sold_out')
  if (soldoutSt) otherMark.push(soldoutSt)
  if (exclusivePrice) otherMark.push('show_s3vipprice')
  if (lable) otherMark.push(lable)
  if (cccTspBadges) otherMark.push(cccTspBadges)
  if (seriesOrBrand) otherMark.push(seriesOrBrand)
  if (ortherExtMark) otherMark.push(ortherExtMark)
  if (sellingPointLabel) otherMark.push(sellingPointLabel)
  if (videoIcon) otherMark.push(videoIcon)
  if (spuImg) otherMark.push(spuImg)
  if (userAct) otherMark.push(userAct)
  if (quickShip) otherMark.push(quickShip)
  if (bestDeal) otherMark.push('show_label_Betention')
  if (promoLabel) otherMark.push('show_icon')
  if (userBehaviorLabel) otherMark.push(userBehaviorLabel)
  if (userCommentKeywordsLabel) otherMark.push(userCommentKeywordsLabel)
  if (beltLabel) otherMark.push(beltLabel)
  if (followLabel) otherMark.push('show_same_label')
  if (decisionAttr) otherMark.push(decisionAttr)
  if (locateLabels) otherMark.push(locateLabels)
  if (historicalPrice) otherMark.push(historicalPrice)
  if (followPrice) otherMark.push(followPrice)
  if (rankingList) otherMark.push(rankingList)
  if (findSimilarMode) otherMark.push(findSimilarMode)
  if (estimatedPriceInfo[0]) otherMark.push(`estimated_price_${estimatedPriceInfo[0]}`)
  if (isABPrice) otherMark.push(isABPrice)
  if (showLabelHot) otherMark.push(showLabelHot)
  if (showSellerLimitedLabel) otherMark.push(showSellerLimitedLabel)
  if (labelLooping) otherMark.push(labelLooping)
  if (serviceLabel) otherMark.push(serviceLabel)
  if (titleLabel) otherMark.push(titleLabel) 
  if (horseRaceLabel) otherMark.push(horseRaceLabel)
  if (rrpPrice) otherMark.push(rrpPrice)
  if (discountCountDown) otherMark.push(discountCountDown)
  if (extraTag) otherMark.push(extraTag)
  if (hypernymGoods) otherMark.push('search_query_upper')
  if (salesLabel) otherMark.push(salesLabel)
  if (labelCarousels) otherMark.push(labelCarousels)
  if (extMarks) otherMark.push(extMarks)
  if(flashSale) otherMark.push(flashSale)
  if(lookType) otherMark.push(lookType)
  if(lookName) otherMark.push(lookName)
  if(realLeftStock) otherMark.push(realLeftStock)
  if (trendWordId) otherMark.push(`trend_tag_${trendWordId}`)
  if (qualityWordId && qualityWordName) otherMark.push(`list_choice_${qualityWordName}_${qualityWordId}`)
  if (trendProductSelectId) otherMark.push(`product_select_id_${trendProductSelectId}`)
  if (trendShopCode) otherMark.push(`trend_shop_code_${trendShopCode}`)
  if (newUserVoucher) otherMark.push(newUserVoucher)  
  if (localGoodsId) otherMark.push(`ypdg_goods_id_${localGoodsId}`)
  if (maskLayer) otherMark.push(maskLayer)
  // 其他标识 - E

  // 销售或促销属性 - S
  const salePromotion = []
  salePromotion.push(sellAttr || '') // 销售属性
  if (promotionId?.length && typeId?.length) {
    const is_brand = brand ? 1 : 0
    promotionId.forEach((pId, index) => {
      salePromotion.push(['sale', typeId[index], pId, is_brand, brandCode].join('_')) // 促销属性
    })
  }
  // 销售或促销属性 - E

  list.push(otherMark.join('|'), salePromotion.join('|'))

  list.push(`${mallCode ? 'mall_' + mallCode : '-'}`) // mall
  list.push([otherDExtMark, request_ext].filter(_ => _).join('|')) // FR-9925
  return list.join('`')
}

/**
 * 设置sa traceId
*/
const setSaTraceId = ({ goodsId = '', traceId = '', isModule = false } = {}) => {
  gbExposeTraceid('setProduct', {
    goods_id: goodsId,
    traceid: traceId
  })

  // 1.3 旧代码copy, 具体业务功能未知
  if (typeof gbAddBagTraceFrom == 'function') {
    gbAddBagTraceFrom('setProductFrom', {
      from: isModule ? 'recommend' : 'list',
      goods_id: goodsId
    })
  }
}

const getSaInfo = ({
  triggerType = 'click', activityFrom = '', isModule = false, isFault = 0, style = '', feed_type = '', traceId = '', dimension = '', saAbt = '',
  goodsList = [], srcModule = '', srcIdentifier = '', tabList = '-', type = '', time = '', activity_id = '', location = '', parentGoodsId = '', beacon = 1, query_ts
}  = {}) => {
  let sa = {
    name: '',
    param: {}
  }
  // 1.1 判断上报类型
  // click_module_goods_list expose_module_goods_list
  // click_goods_list expose_goods_list
  sa.name = isModule ? `${triggerType}_module_goods_list` : `${triggerType}_goods_list`
  sa.param.goods_list = goodsList,
  sa.param.abtest = saAbt
  sa.param.activity_from = activityFrom
  sa.param.style = style
  sa.param.traceid = traceId
  sa.param.tab_list = tabList || '-'
  sa.param.src_module = srcModule
  sa.param.src_identifier = srcIdentifier
  query_ts && (sa.param.query_ts = query_ts)
  if (location) sa.param.location = location
  if (activityFrom === 'similar_sold_out_pre') {
    sa.param.page_from = window.SaPageInfo?.page_name
    sa.param.similar_from = 'similar_sold_out_pre'
    sa.param.style = 'popup'
  }
  // 闪购列表
  if (activityFrom === 'goods_list' && activity_id) {
    sa.param.type = type || ''
    sa.param.time = time || ''
    sa.param.activity_id = activity_id
  }

  if (activityFrom === 'buy_box') {
    sa.param.goods_id = parentGoodsId
    sa.param.feed_type = feed_type
  }
  dimension && (sa.param.dimension = dimension)
  isModule && (sa.param.fault_tolerant = isFault)  // 推荐位容错
  return sa
}

// const getGaInfo = ({
//   triggerType = 'click', code = '', list = '', isModule = false,
//   addProduct = {},
//   addImpressionList = [],
// }) => {
//   let ga = {}
//   // pwa pc 差异化
//   ga.eventCategory = isModule ? '推荐列表' : window.PageGroupOverview
//   ga.eventLabel = list

//   if (triggerType == 'click') {
//     ga.setAction = {
//       type: 'click',
//       param: {
//         list: list
//       }
//     },
//     ga.addProduct = addProduct
//   } else {
//     ga.addImpressionList = addImpressionList
//   }
//   return ga
// }

const clickItemHandler = ({ sa, report }, { target = null } = {}, extendsHandler) => { 
  if (!target) return
  // 获取最近一个类名为含有j-da-event-box的祖先
  let { poskey, isFault, style, feed_type, traceId, dimension, activityFrom, isModule, pageNum, request_ext, srcModule, srcIdentifier, beacon, tabList, type, location, time, activity_id, query_ts } = getAttrFromContainer(target)
  // abt信息
  let { saAbt = '' } = getAnalysisAbtInfo(poskey)
  // 电商代码的list信息
  let goodsList = null // sa

  // item 本身取的数据
  let { historicalPrice, followPrice, locateLabels, index, spu, sku, goodsId, price, originPrice, operId, recMark, extraMark, ortherExtMark, otherDExtMark, extMarks, reducePrice, soldOutSign, soldoutMart, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, brand, videoIcon, spuImg, userAct, mallCode, storeCode, sellAttr, promotionId, typeId, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userCommentKeywordsLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, showSellerLimitedLabel, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, extraTag, hypernymGoods, salesLabel, isABPrice, labelCarousels, realLeftStock, trendWordId, trendProductSelectId, trendShopCode, qualityWordId, qualityWordName, newUserVoucher, localGoodsId, maskLayer } = getAttrFromTarget(target)
  goodsList = getSaGoodsList({ historicalPrice, followPrice, locateLabels, goodsId, sku, spu, index, pageNum, request_ext, operId, recMark, extraMark, ortherExtMark, otherDExtMark, extMarks, price, originPrice, reducePrice, soldOutSign, soldoutMart, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, videoIcon, spuImg, userAct, mallCode, storeCode, sellAttr, promotionId, typeId, brand, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userCommentKeywordsLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, showSellerLimitedLabel, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, extraTag, hypernymGoods, salesLabel, isABPrice, labelCarousels, realLeftStock, trendWordId, trendProductSelectId, qualityWordId, qualityWordName, trendShopCode, newUserVoucher, localGoodsId, maskLayer })
  // 设置sa
  let saInfo = getSaInfo({ triggerType: 'click', activityFrom, isModule, isFault, style, feed_type, traceId, dimension, location, saAbt, goodsList, srcModule, srcIdentifier, tabList, type, time, activity_id, query_ts })
  // 设置sa traceid
  setSaTraceId({ goodsId, traceId, isModule })
  sa = {
    ...sa,
    ...saInfo,
    beacon,
    ...sa.name ? { name: sa.name } : {},  // 优先使用写死的name
  }
  typeof extendsHandler === 'function' && extendsHandler(sa)
  report({
    ...{ sa },
  })
}

const exposeItemHandler = async ({ sa, report }, { target = null } = {}, extendsHandler) => {
  
  if (!target || !Array.isArray(target) || target.length <= 0) return

  // 获取最近一个类名为含有j-da-event-box的祖先
  let { poskey, isFault, style, feed_type, traceId, dimension, activityFrom, parentGoodsId, isModule, pageNum, request_ext, srcModule, srcIdentifier, tabList, type, time, activity_id, location, query_ts } = getAttrFromContainer(target[0])
  // abt信息
  let { saAbt = '' } = getAnalysisAbtInfo(poskey)

  let goodsList = [] // sa
  // addImpressionList = [] // ga

  target.forEach((targetDom) => {
    // item 本身取的数据
    let { historicalPrice, followPrice, locateLabels, index, spu, sku, goodsId, price, originPrice, operId, recMark, extraMark, ortherExtMark, otherDExtMark, extMarks, reducePrice, soldoutMart, soldOutSign, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, brand, videoIcon, spuImg, userAct, mallCode, storeCode, sellAttr, promotionId, typeId, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userCommentKeywordsLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, showSellerLimitedLabel, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, extraTag, hypernymGoods, salesLabel, isABPrice, labelCarousels, realLeftStock, trendWordId, trendProductSelectId, trendShopCode, qualityWordId, qualityWordName, newUserVoucher, localGoodsId, maskLayer } = getAttrFromTarget(targetDom)
    goodsList.push(getSaGoodsList({ historicalPrice, followPrice, locateLabels, goodsId, sku, spu, index, pageNum, request_ext, operId, recMark, extraMark, ortherExtMark, otherDExtMark, extMarks, price, originPrice, reducePrice, soldoutMart, soldOutSign, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, videoIcon, spuImg, userAct, mallCode, storeCode, sellAttr, promotionId, typeId, brand, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userCommentKeywordsLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, showSellerLimitedLabel, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, extraTag, hypernymGoods, salesLabel, isABPrice, labelCarousels, realLeftStock, trendWordId, trendProductSelectId, trendShopCode, qualityWordId, qualityWordName, newUserVoucher, localGoodsId, maskLayer }))
  })

  // 设置sa
  let saInfo = getSaInfo({ triggerType: 'expose', activityFrom, parentGoodsId, tabList, isModule, isFault, style, feed_type, location, traceId, dimension, saAbt, goodsList: goodsList.join(','), srcModule, srcIdentifier, type, time, activity_id, query_ts })
  sa = {
    ...sa,
    ...saInfo,
    ...sa.name ? { name: sa.name } : {},  // 优先使用写死的name
  }
  typeof extendsHandler === 'function' && extendsHandler(sa)

  report({
    ...{ sa },
  })
}

// 推荐面板 title/view more的点击/曝光
const handleFeedbackTitleAndMore = ({ sa, report }, { target }) => {
  const { srcIdentifier } = getAttrFromContainer(Array.isArray(target) ? target[0] : target)
  sa.param.src_identifier = srcIdentifier

  report({ sa })
}

// 推荐面板 close 的点击/曝光
const handleFeedbackClsoe = ({ sa, report }, { target }) => {
  target = Array.isArray(target) ? target[0] : target

  if(!target) return
  sa.param.st = target.getAttribute('data-st')
  sa.param.goods_id = target.getAttribute('data-goods-id')

  report({ sa })
}


export {
  exposeItemHandler,
  clickItemHandler,
  getSaGoodsList,
  pageInfoMapping,
  getAnalysisAbtInfo,
  getAttrFromContainer,
  getAttrFromTarget,
  // getGaList,
}

export default {
  // 商品(推荐位/资源位)的点击
  '2-3-1': {
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      },
    },
    handler: clickItemHandler
  },
  // 商品资源位的曝光
  '2-3-2': {
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      }
    },
    handler: exposeItemHandler
  },
  // 商品点击加车按钮(图标)
  '2-3-5': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_goods_list_addcar',
      param: null
    },
    handler({ sa, report }, { target = null, extraData = {} } = {}) {
      let { activityFrom, poskey, abtest, pageNum, request_ext, tabList, parentGoodsId } = getAttrFromContainer(target)

      const { button_type, isLiseEmpty = false, goodsListParams = '', tab_list = '', need_tab_list = false, location = '', threshold, config = {} } = extraData // 商详

      if (goodsListParams || target) {
        let { historicalPrice, followPrice, locateLabels, index, spu, sku, goodsId, price, originPrice, operId, recMark, extraMark, ortherExtMark, otherDExtMark, extMarks, reducePrice, soldOutSign, soldoutMart, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, brand, videoIcon, spuImg, userAct, mallCode, storeCode, sellAttr, promotionId, typeId, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userCommentKeywordsLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, showSellerLimitedLabel, hypernymGoods, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, extraTag, salesLabel, isABPrice, labelCarousels, realLeftStock, trendWordId, trendProductSelectId, trendShopCode, qualityWordId, qualityWordName, newUserVoucher, localGoodsId, maskLayer } = goodsListParams || getAttrFromTarget(target)

        const goodsList = isLiseEmpty ? '' : getSaGoodsList({ historicalPrice, followPrice, locateLabels, goodsId, sku, spu, index, pageNum, request_ext, operId, recMark, extraMark, ortherExtMark, otherDExtMark, extMarks, price, originPrice, reducePrice, soldOutSign, soldoutMart, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, videoIcon, spuImg, userAct, mallCode, storeCode, sellAttr, promotionId, typeId, brand, brandCode, quickShip, bestDeal, followLabel, promoLabel, userBehaviorLabel, userCommentKeywordsLabel, beltLabel, rankingList, decisionAttr, findSimilarMode, estimatedPriceInfo, showLabelHot, showSellerLimitedLabel, hypernymGoods, labelLooping, serviceLabel, titleLabel, horseRaceLabel, rrpPrice, discountCountDown, extraTag, salesLabel, isABPrice, labelCarousels, realLeftStock, trendWordId, trendProductSelectId, trendShopCode, qualityWordId, qualityWordName, newUserVoucher, localGoodsId, maskLayer })
        // sa
        let { saAbt = '' } = getAnalysisAbtInfo(poskey)
        const params = {
          abtest: saAbt || abtest,
          goods_list: goodsList,
          style: extraData.style || 'popup',
          feed_type: extraData.feed_type || '',
          activity_from: config?.from || activityFrom,
          button_type: 'add_to_bag',
        }
        if (button_type) params.button_type = button_type
        if(need_tab_list) params.tab_list = tab_list
        if(location) params.location = location
        if(threshold) params.threshold = threshold
        parentGoodsId && activityFrom === 'buy_box' && (params.goods_id = parentGoodsId)

        // 促销活动tab
        if(tabList) params.tabList = tabList
        sa.param = params

        return report({ sa })
      }
    }
  },
  // 商品加车 关闭按钮
  '2-3-6': {
    sendType: ['event', 'user'],
    ga: {
      eventCategory: '',
      eventAction: 'AddToBagCancel',
      eventLabel: '',
    },
    handler({ ga, report }, { target = null, extraData = {} } = {}) {
      let { isModule, code } = getAttrFromContainer(target)
      let sku = target.getAttribute('data-sku')
      ga.eventCategory = isModule ? pageInfoMapping(code).overview || 'other' : window.PageGroupOverview
      ga.eventLabel = sku
      report({ ga })
    }
  },
  // 商品 选择尺码
  '2-3-7': {
    sendType: ['event', 'user'],
    ga: {
      eventCategory: '',
      eventAction: 'SelectSize',
      eventLabel: '',
    },
    handler({ ga, report }, { target = null, extraData = {} } = {}) {
      if (Object.keys(extraData).length > 0) {
        ga.eventCategory = pageInfoMapping(extraData.code).overview || 'other'
        ga.eventLabel = extraData.eventLabel
      } else if (target != null) {
        let { isModule, code } = getAttrFromContainer(target)
        ga.eventCategory = isModule ? pageInfoMapping(code).overview || 'other' : window.PageGroupOverview
        ga.eventLabel = target.getAttribute('data-label') || ''
      }

      report({ ga })
    }
  },
  // 商品(推荐位/资源位)的点击
  '2-3-11': {
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      },
      // beacon: true
    },
    handler({ sa, report }, { target = null, extraData = {} } = {},) {
      const extendsHandler = (saObj) => {
        if (saObj && saObj.param) {
          let similarFrom = 'other'
          if (target) {
            const similarScrollContainer = target.closest('.j-similar-product-container')
            similarScrollContainer && (similarFrom = similarScrollContainer.getAttribute('similar-from'))
          }

          saObj.param.similar_from = similarFrom
          saObj.param.page_from = window.SaPageInfo?.page_name || 'other'
        }
      }
      clickItemHandler({ sa, report }, { target, extraData }, extendsHandler)
    }
  },
  '2-3-12': {
    sendType: ['event', 'user'],
    sa: {
      name: '',
      param: {
        goods_list: '',
        abtest: '',
        activity_from: '',
        style: '',
        traceid: ''
      }
    },
    ga: {
      eventCategory: '',
      eventAction: 'ViewItems',
      eventLabel: '',
      /**
       * name: '', sku
       * id: '', spu
       * category: ''
       * price: ''
       * position: ''
       */
      addImpressionList: [],
    },
    handler({ sa, ga, report }, { target = null, extraData = {} } = {},) {
      const extendsHandler = (saObj) => {
        if (saObj && saObj.param) {
          let similarFrom = 'other'
          if (target && target.length) {
            const similarScrollContainer = target[0].closest('.j-similar-product-container')
            similarScrollContainer && (similarFrom = similarScrollContainer.getAttribute('similar-from'))
          }

          saObj.param.similar_from = similarFrom
          saObj.param.page_from = window.SaPageInfo?.page_name || 'other'
        }
      }
      exposeItemHandler({ sa, ga, report }, { target, extraData }, extendsHandler)
    }
  },
  '2-3-13': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_goods_list',
      param: {}
    },
    handler({ sa, report }, { target = [], bindData = [], } = {}) {
      const goodsList = target.map(targetDom => {
        let { index, spu, sku, goodsId, price, originPrice, operId, recMark, extraMark, ortherExtMark, reducePrice, soldoutMart, soldOutSign, soldoutSt, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, brand, videoIcon, spuImg, userAct, mallCode, storeCode, showLabelHot, showSellerLimitedLabel, userCommentKeywordsLabel, labelCarousels } = getAttrFromTarget(targetDom)
        return getSaGoodsList({ goodsId, sku, spu, index, pageNum: 1, operId, recMark, extraMark, ortherExtMark, price, originPrice, reducePrice, soldoutMart, soldOutSign, soldoutSt, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, videoIcon, spuImg, userAct, mallCode, storeCode, showLabelHot, showSellerLimitedLabel, userCommentKeywordsLabel, labelCarousels })
      })
      sa.param = {
        goods_list: goodsList.join(','),
        activity_from: bindData.length && bindData[0].activity_from || '',
        style: bindData.length && bindData[0].style || '',
        sets_list: bindData.length && bindData[0].sets_list || '',
        abtest: bindData.length && bindData[0].abtest || '',
        scene_list: bindData.length && bindData[0].scene_list || '',
      }
      report({ sa })
    }
  },
  '2-3-14': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_goods_list',
      param: {}
    },
    handler({ sa, report }, { target = null, extraData = {  } } = {}) {
      let { index, spu, sku, goodsId, price, originPrice, operId, recMark, extraMark, ortherExtMark, reducePrice, soldoutMart, soldOutSign, soldoutSt, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, videoIcon, spuImg, userAct, mallCode, storeCode, showLabelHot, showSellerLimitedLabel, userCommentKeywordsLabel, labelCarousels } = getAttrFromTarget(target)
      sa.param = {
        goods_list: getSaGoodsList({ goodsId, sku, spu, index, pageNum: 1, operId, recMark, extraMark, ortherExtMark, price, originPrice, reducePrice, soldoutMart, soldOutSign, soldoutSt, exclusivePrice, lable, seriesOrBrand, cccTspBadges, sellingPointLabel, videoIcon, spuImg, userAct, mallCode, storeCode, showLabelHot, showSellerLimitedLabel, userCommentKeywordsLabel, labelCarousels }),
        activity_from: extraData.activity_from || '',
        sets_list: extraData.sets_list,
        style: extraData.style || '',
        abtest: extraData.abtest || ''
      }
      report({ sa })
    }
  },
  '2-3-16': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_sellout_toast'
    }
  },
  // 实时反馈商品推荐title:曝光
  '2-3-17': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_feedback_title',
      param: {
        activity_from: 'realtime_feedback',
        src_module: 'feedback',
      }
    },
    handler: handleFeedbackTitleAndMore
  },
  // 实时反馈商品推荐title: 点击
  '2-3-18': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_feedback_title',
      param: {
        activity_from: 'realtime_feedback',
        src_module: 'feedback',
      }
    },
    handler: handleFeedbackTitleAndMore
  },
  // 实时反馈商品推荐 close:曝光
  '2-3-19': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_feedback_close',
      param: {
        activity_from: 'realtime_feedback',
      }
    },
    handler: handleFeedbackClsoe
  },
  // 实时反馈商品推荐 close:点击
  '2-3-20': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_feedback_close',
      param: {
        activity_from: 'realtime_feedback',
      }
    },
    handler: handleFeedbackClsoe
  },
  // 实时反馈商品推荐 view more:曝光
  '2-3-21': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_feedback_view_more',
      param: {
        activity_from: 'realtime_feedback',
        src_module: 'feedback',
      }
    },
    handler: handleFeedbackTitleAndMore
  },
  // 实时反馈商品推荐 view more:点击
  '2-3-22': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_feedback_view_more',
      param: {
        activity_from: 'realtime_feedback',
        src_module: 'feedback',
      }
    },
    handler: handleFeedbackTitleAndMore
  },
  // 点击找相似弹窗
  '2-3-23': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_findsimilar',
      param: {}
    },
    handler({ sa, report }, { bindData = {} } = {}) {
      sa.param = bindData
      report({ sa })
    }
  },
  // 找相似弹窗曝光
  '2-3-24': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_findsimilar',
      param: {}
    },
    handler({ sa, report }, { bindData = [] } = {}) {
      const params = bindData?.[0] || {}
      sa.param = params
      report({ sa })
    }
  },
  // 商铺入口曝光
  '2-3-25': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_store_name',
      param: {
        store_code: '',
        goods_id: '',
      }
    },
    handler({ sa, report }, { bindData = [] } = {}) {
      const storeCode = bindData?.[0]?.storeCode
      const goodsId = bindData?.[0]?.goodsId
      sa.param.store_code = storeCode
      sa.param.goods_id = goodsId
      report({ sa })
    }
  },
  // 商铺入口点击
  '2-3-26': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_store_name',
      param: {
        store_code: '',
        goods_id: '',
      }
    },
    handler({ sa, report }, { bindData = null } = {}) {
      const storeCode = bindData?.storeCode
      const goodsId = bindData?.goodsId
      sa.param.store_code = storeCode
      sa.param.goods_id = goodsId
      report({ sa })
    }
  },
  // 曝光榜单
  '2-3-39': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_ranking_list_label',
    },
    handler({ sa, report }, { bindData = [] } = {}) {
      const params = bindData?.[0] || {}
      sa.param = params
      report({ sa })
    },
  },
  // 赛马标签
  '2-3-42': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_list_sorting_tag',
    },
    handler({ sa, report }, { extraData = {}, bindData = {} } = {}) {
      const { analysisCb } = bindData?.[0] || {}
      if (typeof analysisCb === 'function') {
        return analysisCb()
      }
      sa.param = {
        ...(sa?.param || {}),
        ...extraData
      }
      report({ sa })
    }
  },
  // buy box 曝光
  '2-3-40': {
    sendType: ['event', 'user'],
    sa: {
      name: 'expose_buy_box_block',
      param: {}
    },
    handler ({ sa, report }, { target = null } = {}) {
      if (!target) return
      const dom = target?.length ? target[0] : target

      sa.param = {
        goods_id: dom.getAttribute('data-goods-id'),
        expose_times: dom.getAttribute('data-expose-times'),
        buy_box_style: dom.getAttribute('data-buy-box-style'),
      }

      report({ sa })
    }
  },
  // buy box 点击
  '2-3-41': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_buy_box_block',
      param: {}
    },
    handler ({ sa, report }, { extraData = {} } = {}) {
      sa.param = extraData
      report({ sa })
    }
  },
  '2-3-49': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_trend_tag',
      param: {
        goods_id: '',
      }
    },
    handler({ sa, report }, { extraData = {} } = {}) {
      sa.param.goods_id = extraData?.goodsId
      sa.param.page_number = extraData?.pageNumber
      sa.param.product_select_id = extraData?.product_select_id
      sa.param.result_order = extraData?.resultOrder
      if(extraData?.trend_word_id !== '-'){
        sa.param.src_identifier = `trend=${extraData?.trend_word_id}`
      } else if (extraData?.trend_shop_code !== '-') {
        sa.param.src_identifier = `store=${extraData?.trend_shop_code}`
      } else if (extraData?.choices_word_id !== '-') {
        sa.param.src_identifier = 'choices=' + extraData?.choices_word_id
      }
      sa.param.src_module = extraData?.src_module || ''
      sa.param.trend_word_id = extraData?.trend_word_id
      sa.param.trend_shop_code = extraData.trend_shop_code
      sa.param.choices_word_id = extraData?.choices_word_id
      report({ sa })
    }
  },
  // 点击榜单标签跳转榜单
  '2-3-50': {
    sendType: ['event', 'user'],
    sa: {
      name: 'click_ranking_list_label',
      param: {
        goods_id: '',
        src_module: '',
        src_identifier: '',
        content_id: ''
      }
    },
    handler({ sa, report }, { bindData = {} }) {
      sa.param = bindData
      report({ sa })
    }
  }
}
